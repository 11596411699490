
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>合作伙伴管理</el-breadcrumb-item>
      <el-breadcrumb-item>伙伴管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="isCert">
            <div style="display: inline-block" class="divSpan">实名认证：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.isCert"
                :options="CertOptions"
                :optionKey="ParentIdkeys"
                Splaceholder="请选择"
                style="width: 120px"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">企业名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.name"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="referrerPhone">
            <div style="display: inline-block" class="divSpan">推荐码：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.referrerPhone"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
          <el-button
            type="primary"
            style="margin-left: 100px"
            @click="addPartner"
            >新增合作伙伴</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ModelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="company"
            label="企业名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="companyAlias"
            label="企业展示名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="status" label="实名认证" min-width="100px">
            <template slot-scope="scope">
              <el-tag>{{
                Number(scope.row.status) == 1 ? "已实名" : "未实名"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="referrerPhone"
            label="推荐码"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="gradeName"
            label="伙伴等级"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="联系手机号"
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="申请时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="productShelfNum"
            label="上架产品数量"
            min-width="120px"
          ></el-table-column>

          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <!-- <el-button type="primary" size="mini" @click="views(scope.row)"
                >详情</el-button
              > -->
              <el-button type="primary" size="mini" @click="levelSet(scope.row)"
                >评级</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="新增合作伙伴"
      :visible.sync="addFormDialog"
      width="30%"
      @close="addFormDialogClosed"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="用户ID：" prop="userId">
          <el-input v-model.trim="addForm.userId"></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="contact">
          <el-input v-model.trim="addForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="企业名称：" prop="company">
          <el-input v-model.trim="addForm.company"></el-input>
        </el-form-item>

        <el-form-item
          label="企业简称："
          prop="companyAlias"
          :rules="addFormRules.company"
        >
          <el-input v-model.trim="addForm.companyAlias"></el-input>
        </el-form-item>
        <!-- <el-form-item label="推荐人：" prop="referrer">
          <el-input v-model.trim="addForm.referrer"></el-input>
        </el-form-item> -->
        <el-form-item label="推荐码：" prop="referrerPhone">
          <el-input
            placeholder="请输入推荐人的手机号"
            v-model.trim="addForm.referrerPhone"
          ></el-input>
        </el-form-item>

        <el-form-item label="伙伴等级：" prop="gradeId">
          <ELselect
            v-model="addForm.gradeId"
            :options="LevelOptions"
            :optionKey="ParentIdkeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addConfirm" v-preventReClick
          >确定</el-button
        >
        <el-button @click="addFormDialog = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="评级"
      :visible.sync="ApprovalDialog"
      width="30%"
      @close="ApprovalDialogClosed"
      center
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="企业名称：" prop="name">
          <el-input v-model.trim="editForm.name" disabled></el-input>
        </el-form-item>

        <el-form-item label="伙伴等级：" prop="gradeId">
          <ELselect
            v-model="editForm.gradeId"
            :options="LevelOptions"
            :optionKey="ParentIdkeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passApproval" v-preventReClick
          >确定</el-button
        >
        <el-button @click="ApprovalDialog = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看详情"
      :visible.sync="viewFormDialog"
      width="40%"
      center
    >
      <el-form :model="viewForm" label-width="140px">
        <el-form-item label="企业名称：">
          <el-input v-model.trim="viewForm.company"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码：">
          <el-input v-model.trim="viewForm.uniCode"></el-input>
        </el-form-item>
        <el-form-item label="详细通讯地址：">
          <el-input v-model.trim="viewForm.address"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名：">
          <el-input v-model.trim="viewForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号：">
          <el-input v-model.trim="viewForm.contactPhone"></el-input>
        </el-form-item>
        <el-form-item label="联系人身份证号：">
          <el-input v-model.trim="viewForm.contactId"></el-input>
        </el-form-item>
        <el-form-item label="营业执照：">
          <el-image
            :src="viewForm.url"
            :preview-src-list="srcList"
            @click="ImgPre"
            style="width: 50%; margin-top: 1px; margin-right: 10px"
          ></el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { getPartnerAuditList, setUserGrade, addPartner, auditcert } from '@/api/partner.js'
import { getAllGrade } from './common.js'
import { validatorInput } from '@/components/myself/speciaChaFilt.js'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        name: '',
        referrer: '',
        isCert: '',
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      ModelList: [],

      ApprovalDialog: false,
      editForm: {
        name: '',
        gradeId: '',
        userId: '',
      },
      editFormRules: {
        gradeId: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },

      LevelOptions: [],
      ParentIdkeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
      addFormDialog: false,

      addForm: {
        userId: '',
        contact: '',
        company: '',
        gradeId: '',
        referrer: '',
        referrerPhone: '',
        companyAlias: '',
      },
      addFormRules: {
        userId: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
        ],
        company: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
        ],
        gradeId: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        referrerPhone: [
          { validator: validatorInput, trigger: 'blur' },
          {
            max: 20, message: '长度必须在50个字符间', trigger: 'blur'
          }
        ],
        referrer: [
          { validator: validatorInput, trigger: 'blur' },
          {
            max: 40, message: '长度必须在20个字符间', trigger: 'blur'
          }
        ]
      },

      CertOptions: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '已实名'
        },
        {
          id: 2,
          name: '未实名'
        },
      ],

      // 
      viewFormDialog: false,
      viewForm: {},
      srcList: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let quiese = { auditStatus: 2, company: this.queryinfo.name, referrerPhone: this.queryinfo.referrerPhone, isCert: this.isCert }
      const res = await getPartnerAuditList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ModelList = res.data.data.list
      this.total = res.data.data.total
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },
    async addPartner () {
      this.LevelOptions = await getAllGrade(true)
      this.addFormDialog = true

    },
    addFormDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    async addConfirm () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const res = await addPartner(this.addForm)
          if (res.status !== 200) return this.$message.error('获取失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.addFormDialog = false
          this.getList()
        }
      })
    },
    async levelSet (para) {
      this.editForm.userId = para.userId
      this.editForm.name = para.company
      this.editForm.gradeId = para.gradeId
      this.LevelOptions = await getAllGrade(true)
      this.ApprovalDialog = true
    },
    ApprovalDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    async passApproval () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const res = await setUserGrade(this.editForm.userId, this.editForm.gradeId)
          if (res.status !== 200) return this.$message.error('获取失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.ApprovalDialog = false
          this.getList()
        }
      })
    },

    async views (para) {
      const res = await auditcert(para.id)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.viewForm = res.data.data
      this.viewFormDialog = true
    },
    ImgPre () {
      this.srcList = [this.viewForm.url]
    },
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>